import React, { useState, useContext, useEffect } from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { LocationContext } from "../contexts/LocationContext"
import LocationSelect from "./LocationSelect"

const Container = styled.section`
  max-width: 130rem;
  padding: 3rem 1.5rem;
  margin: 0 auto;
`

const Content = styled.div`
  padding: 3rem 0;
`

// const Primary = styled.h2`
//   text-align: center;
//   font-size: 3.5rem;
//   font-weight: 700;
//   text-transform: uppercase;
//   line-height: 1.3;
//   color: #2196f3;
//   background: linear-gradient(to right, #2196f3, #2dc7ff);
//   -webkit-background-clip: text;
//   background-clip: text;
//   -webkit-text-fill-color: transparent;
//   text-fill-color: transparent;
// `

// const Subtitle = styled.h3`
//   font-size: 1.9rem;
//   font-weight: 400;
//   text-align: center;
//   padding: 1.5rem 10rem;
//   @media only screen and (max-width: 52em) {
//     padding: 1.5rem 0;
//   }
// `

const SelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    margin-right: 1rem;
  }
`

const Wrapper = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 6px rgba(0, 21, 64, 0.25);
  @media only screen and (max-width: 45em) {
    display: block;
    overflow-x: scroll;
  }
`

const ColGroup = styled.colgroup`
  col:nth-child(1),
  col:nth-child(3) {
    width: 25%;
    min-width: 18rem;
  }

  col:nth-child(2) {
    width: 50%;
    min-width: 36rem;
  }
`

const Header = styled.thead`
  background-color: #fafafa;
`

const Row = styled.tr``

const Head = styled.th`
  padding: 2rem;
  font-weight: 400;
  text-align: left;
  color: #2196f3;
  text-transform: capitalize;
`

const Province = styled(Head)`
  font-weight: 700;
  background-color: #afafaf;
  color: white;
`

const TBody = styled.tbody`
  tr {
    &:nth-of-type(2n + 1) {
      background-color: rgba(0, 81, 193, 0.05);
    }
  }
`

const Cell = styled.td`
  padding: 2rem;
  &:nth-of-type(odd) {
    background-color: rgba(0, 81, 193, 0.05);
  }
`

const List = styled.ul`
  padding-left: 2rem;
`

const CommodityTable = ({ data: { type, descriptorWording, data } }) => {
  const { loc } = useContext(LocationContext)

  function getSelectedCommodityProvinceData() {
    let provinceData = data.find((obj) => obj.province == loc.province)
    if (!provinceData) {
      return data[0]
    }
    return provinceData
  }

  const [
    selectedCommodityProvinceData,
    setSelectedCommodityProvinceData,
  ] = useState(() => getSelectedCommodityProvinceData())

  useEffect(() => {
    setSelectedCommodityProvinceData(getSelectedCommodityProvinceData())
  }, [loc])

  function toSentenceCase(string) {
    const text = string.replace(/([A-Z])/g, " $1")
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  function renderProvinceHeader() {
    return (
      <Province colSpan="3">
        {selectedCommodityProvinceData
          ? toSentenceCase(selectedCommodityProvinceData.province)
          : toSentenceCase(data[0].province)}
      </Province>
    )
  }

  function renderPropertyList(commodityItemsArr) {
    let commodityItemsObj = commodityItemsArr.reduce((acc, curr) => {
      let splitItm = curr.split(" | ")
      let main = splitItm[0],
        sub = splitItm[1]

      acc[main] = acc[main] || []

      if (sub) {
        acc[main].push(sub)
      }

      return acc
    }, {})

    return (
      <List>
        {Object.keys(commodityItemsObj).map((main) => (
          <React.Fragment>
            <li>{main}</li>
            {commodityItemsObj[main].length > 0 && (
              <List>
                {commodityItemsObj[main].map((sub) => (
                  <li>{sub}</li>
                ))}
              </List>
            )}
          </React.Fragment>
        ))}
      </List>
    )
  }

  return (
    <Container>
      {/* <Primary>{title}</Primary>
      <Subtitle>{subtitle}</Subtitle> */}
      <SelectWrapper>
        <div>Not your city?</div>
        <LocationSelect color="#505050" fontSize="2.5rem" />
      </SelectWrapper>
      <Content>
        <Wrapper>
          <ColGroup>
            <col />
            <col />
            <col />
          </ColGroup>
          <Header>
            <Row>{renderProvinceHeader()}</Row>
          </Header>
          <TBody>
            <Row>
              <Head>
                {type} {descriptorWording || "Covered"}
              </Head>
              <Cell>
                {renderPropertyList(selectedCommodityProvinceData["yes"])}
              </Cell>
              <Cell>
                {renderPropertyList(
                  selectedCommodityProvinceData["depositFees"]
                )}
              </Cell>
            </Row>
            <Row>
              <Head>
                {type} Not {descriptorWording || "Covered"}
              </Head>
              <Cell>
                {renderPropertyList(selectedCommodityProvinceData["no"])}
              </Cell>
              <Cell>
                <List>
                  <li>$0 CAD</li>
                </List>
              </Cell>
            </Row>
          </TBody>
        </Wrapper>
      </Content>
    </Container>
  )
}

export default CommodityTable
